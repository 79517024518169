import React from "react";

function FollowUs() {
  return (
    <div className="flex items-center justify-center w-screen text-white p-3">
      {/* <marquee className="text-4xl">Follow Us on Youtube</marquee> */}
    </div>
  );
}

export default FollowUs;
